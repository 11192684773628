import { useState, useEffect } from "react"

// Components
import { Sections } from "./components/_Sections";

// Utils
import Axios from "axios";

const url = process.env.NODE_ENV === "development" 
? 'http://localhost:3001' 
: `https://${document.location.hostname}`;

type ResponseSeating = {
    id: number;
    pos_x: number;
    pos_y: number;
    availability: string;
    realm_id: number;
    section_id: number;
    number: number;
    waiter_character_id: number | null;
    image_url: string;
    section_name: string;
}

type SimplifiedSeating = {
    id: number;
    number: number;
    pos_x: number;
    pos_y: number;
    is_available: boolean;
}

export type SimplifiedSection = {
    id: number;
    name: string;
    image_url: string;
    seatings: SimplifiedSeating[];
}

function _parseSeatings(seatings: ResponseSeating[]) {
    const sections: SimplifiedSection[] = [];

    seatings.forEach(seating => {
        const existingSectionIndex = sections.findIndex(section => section.id === seating.section_id);

        if (existingSectionIndex >= 0) {
            sections[existingSectionIndex].seatings.push(_convertToSimplifiedSeating(seating));
        } else {
            sections.push({
                id: seating.section_id,
                name: seating.section_name,
                image_url: seating.image_url.trim(),
                seatings: [_convertToSimplifiedSeating(seating)]
            });
        }
    });

    return sections;
}

function _convertToSimplifiedSeating(seating: ResponseSeating): SimplifiedSeating {
    return {
        id: seating.id,
        number: seating.number,
        pos_x: seating.pos_x,
        pos_y: seating.pos_y,
        is_available: seating.availability === 'available'
    }
}

export default function PublicSeating() {
    const [ sections, setSections ] = useState<null | SimplifiedSection[]>(null);

    useEffect(() => {
        Axios.get(url + '/api/seatings?realm_id=1')
        .then(res => {
            setSections(_parseSeatings(res.data))
        });
    }, []);

    return (
        <div className='PublicSeating'>
            <Sections sections={sections} />
        </div>
    )
}