import { useState, useEffect } from "react";

// Types
import { type SimplifiedSection } from "../PublicSeating"

// Components
import Button from "./../../../components/common/Button/Button";

interface ISections {
    sections: SimplifiedSection[] | null;
}

export function Sections({ sections }: ISections) {
    const [ selectedSection, setSelectedSection ] = useState(sections?.[0] || null);

    function handleSectionClick(index: number) {
        setSelectedSection(sections?.[index] || null);
    }

    useEffect(() => {
        if (!sections) return;

        setSelectedSection(sections[0]);
    }, [sections]);
    

    return (
        <div className="Sections">
            <div className='header'>
                <nav>
                    {sections?.map((section, index) => (
                        <Button 
                            type={selectedSection?.id === section.id ? 'progressive' : 'dark'}
                            key={`Section-${index}`}
                            clickEvent={() => {handleSectionClick(index)}}
                        >
                            {section.name}
                        </Button>
                    ))

                    }
                </nav>
            </div>

            <div className='section'>
                <img src={selectedSection?.image_url} alt='Section Background' />
            </div>

        </div>
    )
}